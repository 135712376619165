<template>
  <v-expansion-panels flat tile>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="font-weight-bold mb-8">Code Generator (API usage)</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-tabs color="primary" v-model="tab" right dense>
          <v-tab v-for="lang in languages" :key="lang.tab">
            <v-icon left>{{ lang.icon }}</v-icon>
            {{ lang.tab }}
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="lang in languages" :key="lang.tab">
              <v-layout wrap class="justify-center">
                <v-flex lg5 md5 sm12>
                  <v-container fluid>
                    <v-radio-group v-model="radioGroup" dense>
                      <v-radio
                        v-for="option in radioGroupOptions"
                        :key="option.id"
                        :label="`${option.description}`"
                        :value="option.id"
                      ></v-radio>
                    </v-radio-group>

                    <v-select
                      v-if="radioGroup !== 'delete'"
                      class="mt-2"
                      outlined
                      dense
                      v-model="model"
                      :items="models"
                      item-text="name"
                      item-value="name"
                      label="Model"
                    ></v-select>

                    <v-select
                      v-if="radioGroup === 'list'"
                      class=""
                      outlined
                      dense
                      v-model="status"
                      :items="statuses"
                      item-text="text"
                      item-value="value"
                      label="Status"
                    ></v-select>

                    <v-text-field                    
                      v-if="radioGroup !== 'delete'"
                      dense
                      :label="`User ${timeBasedLimitsEnabled ? '(required)': '(optional)'}`"
                      v-model="user"
                      outlined
                    ></v-text-field>
                    
                    <v-select
                      v-if="radioGroup === 'generate'"
                      class=""
                      outlined
                      dense
                      v-model="priority"
                      :items="priorityClass"
                      item-text="text"
                      item-value="value"
                      label="Priority class"
                    ></v-select>

                    <v-checkbox
                      v-if="radioGroup === 'generate'"
                      v-model="asyncRequest"
                      label="Don't wait for the response"
                      color="primary"                      
                      hide-details
                    ></v-checkbox>                    

                    <v-text-field
                      v-if="radioGroup === 'delete'"
                      dense
                      label="Image generation ID"
                      v-model="imageId"
                      outlined
                    ></v-text-field>
                  </v-container>
                </v-flex>
                <v-flex lg7 md7 sm12>
                  <markup
                    class="mt-5"
                    :language="lang.lang"
                    :code="snippet(lang, false)"
                    :copyCode="snippet(lang, true)"
                    :inline="false"
                  ></markup>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import { HTTPSnippet } from '@readme/httpsnippet'
import Markup from '../helpers/Markup';

const generateImage = 'generate'
const remainingGenerations = 'remaining'
const listGeneratedImages = 'list'
const deleteGeneratedImage = 'delete'

export default {
  components: {
    Markup,
  },

  data: () => ({
    radioGroup: generateImage,
    radioGroupOptions: [
      {
        id: generateImage,
        description: 'Generate image'
      },
      {
        id: remainingGenerations,
        description: 'Remaining generations (for user)'
      },
      {
        id: listGeneratedImages,
        description: 'List images'
      },  
      {
        id: deleteGeneratedImage,
        description: 'Delete image'
      }
    ],
    asyncRequest: false,
    tab: {
      lang: 'shell',
      tab: 'Shell',
    },
    languages: [
      { lang: 'shell', tab: 'Shell', icon: 'mdi-console-line' },
      { lang: 'javascript', tab: 'JavaScript', icon: 'mdi-language-javascript' },
      { lang: 'python', tab: 'Python', icon: 'mdi-language-python' },
    ],
    model: '',
    user: '',
    imageId: '',
    status: '',
    statuses: [
      { text: 'Pending', value: 'pending' },
      { text: 'Processing', value: 'processing' },
      { text: 'Completed', value: 'completed' },
      { text: 'Failed', value: 'failed' },
    ],
    priorityClass: [
      { text: 'Not set', value: '' },
      { text: 'High', value: 'high' },
      { text: 'Medium', value: 'medium' },
      { text: 'Low', value: 'low' },      
    ],
    priority: ''
  }),

  props: {
    apiToken: String,
    models: Array,    
  },

  computed: {
    timeBasedLimitsEnabled() {
      if (this.models.length === 0) {
        return false
      }
      if (this.model === '') {
        return false
      }
      // Check if the selected model has time based limits enabled
      const model = this.models.find(c => c.name === this.model)
      return model.rules.timeBasedLimits
    }
  },

  methods: {
    snippet(lang, withCredentials) {
      let apiToken = '**********'
      if (withCredentials) {
        apiToken = this.apiToken        
      }

      if (this.model === '' && this.models.length > 0) {
        this.model = this.models[0].name
      } else if (this.models.length === 0) {
        this.model = '[your-model]'
      }

      if (this.radioGroup === remainingGenerations) {
        return this.remainingGenerationsSnippet(lang, this.model, this.user, apiToken)
      }

      if (this.radioGroup === deleteGeneratedImage) {
        return this.deleteImagesSnippet(lang, this.imageId, apiToken)
      }

      if (this.radioGroup === generateImage) {
        return this.generateImageSnippet(lang, this.model, this.user, this.priority, apiToken, this.asyncRequest)
      }
      return this.listImagesSnippet(lang, this.model, this.status, this.user, apiToken)
    },

    generateImageSnippet(lang, model, user, priority, apiToken, asyncRequest) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
        {
          name: 'X-Model',
          value: model
        },
        {
          name: 'X-Async',
          value: `${asyncRequest ? 'true' : 'false'}`
        },
      ]

      if (user.length > 0) {
        headers.push({
          name: 'X-User',
          value: user
        })
      }
      
      if (priority.length > 0) {
        headers.push({
          name: 'X-Priority',
          value: priority
        })
      }

      const snippet = new HTTPSnippet({
        method: 'POST',
        url: `${window.location.protocol}//${window.location.host}/api/images/generations`,
        headers: headers,
        postData: {
          text: `{"prompt": "futuristic city, extra detailed"}`,
          mimeType: "application/json"
        }
      })

      return this.convertSnippet(lang, snippet)
    },

    remainingGenerationsSnippet(lang, model, user, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'GET',
        url: `${window.location.protocol}//${window.location.host}/api/remaining/generations`,
        headers: headers,
        queryString: [
          {
            name: 'user',
            value: user
          },
          {
            name: 'model',
            value: model
          },
        ]
      })

      return this.convertSnippet(lang, snippet)
    },

    listImagesSnippet(lang, model, status, user, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]

      let query = []

      if (user.length > 0) {
        query.push({
          name: 'user',
          value: user
        })
      }

      if (model !== '') {
        query.push({
          name: 'model',
          value: model
        })
      }
      
      if (status !== '') {
        query.push({
          name: 'status',
          value: status
        })
      }

      const snippet = new HTTPSnippet({
        method: 'GET',
        url: `${window.location.protocol}//${window.location.host}/api/images/generations`,
        headers: headers,
        queryString: query
      })

      return this.convertSnippet(lang, snippet)
    },

    deleteImagesSnippet(lang, id, apiToken) {
      let headers = [
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'DELETE',
        url: `${window.location.protocol}//${window.location.host}/api/images/generations/${id}`,
        headers: headers,        
      })

      return this.convertSnippet(lang, snippet)
    },

    convertSnippet(lang, snippet) {
      const options = { indent: '\t' };

      switch (lang.lang) {
        case 'shell':
          return snippet.convert('shell', 'curl', options)
        case 'javascript':
          return snippet.convert('node', 'axios', options)
        case 'python':
          return snippet.convert('python', 'requests', options)
      }
    }
  }
}

</script>